import axios from "axios";
import ReduxConfig  from "../redux/config/store";

const instance = axios;

/**
*   @Description    Axios interceptor, forces token creation and adds that token to the authorization header. Throws an error on failure.
*/

instance.interceptors.request.use(
  async (config : any  )=> {

      const store = ReduxConfig.store;
      const state = store.getState();
      const token = state.User.token

       if (token) {
           config.headers['Authorization'] = 'Bearer ' + token;
           return config;
       } else {
           console.log(config.url);
           if ( config.url ) {
               if ( config.url.includes('/api/login') || config.url.includes('/api/register') ) {
                   config.headers['Authorization'] = null;
                   return config;
               }
           } else {
               throw new instance.Cancel('No token acquired');
           }
       }
   },
   error => {
       Promise.reject(error)
   });


/*
instance.interceptors.request.use(function (config) {
    const store = ReduxConfig.store;
    const state = store.getState();
    const token = state.User.token
    config.headers.Authorization = `${token}`

    if ( !token ) {
        if ( config.url ) {
            if ( !config.url.includes('/api/login') ) {
                throw new instance.Cancel('No token acquired');
            }
        }
    }

    return config
})*/

export default instance;
