import {
    SET_CONTACTS,
} from "./actionTypes";

import { ContactActions, ContactState } from "./types";

const initialState: ContactState = {
    contacts : [],
    numberOfUpdates : 0
};

const reducer = (state = initialState, action: ContactActions) => {
    switch (action.type) {
        case SET_CONTACTS:
            return {
                ...state,
                contacts : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1
            }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
