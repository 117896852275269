import React, { useState }  from 'react';
import { NavLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

//import { _userHasPermission } from '../../helper/permission';
/**
*   Header, layout component
*   @component
*/
const Header = (props : any) => {
    return (
        <header className="header">
            <div className="header__top"></div>
            <div className="header__bottom">
                <div className="container">
                    <div className="header__inner">
                        <div className="header__inner__grid">
                            <div className="header__inner__grid__menu">
                                { props.isLoggedIn ?
                                    <nav>
                                        <ul>
                                            <li><NavLink to="/">Contactgegevens</NavLink></li>
                                            <li><NavLink to="/user">Gebruikers</NavLink></li>
                                        </ul>
                                    </nav>
                                : null }
                            </div>
                            <div className="header__inner__grid__logo">
                                <div className="header__inner__grid__logo__wrapper">
                                    <NavLink to="/">
                                        Contactgegevens
                                    </NavLink>
                                </div>
                            </div>
                            <div className="header__inner__grid__actions">
                                { props.isLoggedIn ?
                                    <nav>
                                        <ul>
                                            <li><NavLink to="/logout">Uitloggen</NavLink></li>
                                        </ul>
                                    </nav>
                                    : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
