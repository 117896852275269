import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from "redux-first-history";


// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  //other options if needed
});

// Mount it on the Store
const store = configureStore({
    reducer : rootReducer(routerReducer),
    middleware : [ sagaMiddleware, logger, routerMiddleware]
    }
);

// Run the saga
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const history = createReduxHistory(store);

const storeConfig = { store, history }

export default storeConfig;
