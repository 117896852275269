import { useEffect } from 'react';
import { FontAwesomeIcon}  from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'


const Popup = (props : any) => {

    useEffect(() => {
        const handleEsc = (event : any) => {
        if (event.keyCode === 27) {
            props.dismiss();
        }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
    return (
        <div className="popup">

            <div className="popup__window">
                <div className="popup__window__header">
                    <div className="popup__window__header__title">
                        <h2>Weet je zeker dat je dit wilt verwijderen?</h2>
                    </div>
                    <div className="popup__window__header__dismiss">
                        <div className="popup__window__header__dismiss__icon" onClick={props.dismiss}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                </div>
                <div className="popup__window__footer popup__window__footer--dual">
                    <div className="button button--red" onClick={props.confirm}>Ja, verwijderen</div>
                    <div className="button button--black" onClick={props.dismiss}>Sluiten</div>
                </div>
            </div>

        </div>


    )
}
export default Popup
