import { all } from 'redux-saga/effects';

import userSaga from '../user/saga';
import messageSaga from '../message/saga';
import contactSaga from '../contact/saga';

export default function* rootSaga() {
	yield all([
		userSaga(),
		messageSaga(),
		contactSaga()
	]);
}
