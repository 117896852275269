import React, { Suspense }  from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../redux/config/store';
import ContactList from '../contact/list';
import UsersList from '../user/list';
import ContactForm from '../contact/form';
import UserForm from '../user/form'
import Logout from '../auth/logout'
/**
*   @component
*	@Description     Router containing all routes and redirects
*/
const Router = (props : any) => {

    return (
        <Routes>
            <Route
                path="/login/"
                element={<Navigate to="/" />}
            />
            <Route
                path="/logout/"
                element={<Logout />}
            />

            <Route
                path="/"
                element={<Navigate to="/contact" />}
            />

            <Route
                path="/contact"
                element={
                    <Suspense fallback={<div />}>
                        <ContactList />
                    </Suspense>
                }
            />
            <Route
                path="/contact/create"
                element={
                    <Suspense fallback={<div />}>
                        <ContactForm />
                    </Suspense>
                }
            />
            <Route
                path="/contact/update/:id"
                element={
                    <Suspense fallback={<div />}>
                        <ContactForm />
                    </Suspense>
                }
            />
            <Route
                path="/user"
                element={
                    <Suspense fallback={<div />}>
                        <UsersList />
                    </Suspense>
                }
            />
            <Route
                path="/user/create"
                element={
                    <Suspense fallback={<div />}>
                        <UserForm />
                    </Suspense>
                }
            />
            <Route
                path="/user/update/:id"
                element={
                    <Suspense fallback={<div />}>
                        <UserForm />
                    </Suspense>
                }
            />

        </Routes>
    )
}


const mapStateToProps = ( state : RootState ) => {

    return {

    }
}

export default connect( mapStateToProps )( Router );
