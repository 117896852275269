import { getAllContactsRequest } from '../contact/actions';
import { getUsers } from '../user/actions';
import {  AppDispatch } from './store';
const boot = (dispatch : AppDispatch) => {
	new Promise(() => {
		dispatch(getAllContactsRequest());
		dispatch(getUsers());
	});
}

export default boot;
