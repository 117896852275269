import { useState }  from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../redux/config/store';
import Popup from './popup';
import ReactTable from 'react-table'
import { FontAwesomeIcon}  from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons'

/**
*   @component
*	@Description     Router containing all routes and redirects
*/
const Router = (props : any) => {

    const [ searchValue, setSearchValue ] = useState<string>('');
    const [ activePopup, setActivePopup ] = useState<Contact | undefined>(undefined);

    const columns = [
        {
            Header: 'Achternaam',
            accessor: 'last_name',
            Cell: (props : any) => <span className="popup-toggle" onClick={() => setActivePopup(props.original)}>{props.value}</span>
        },
        {
            Header: 'Voornaam',
            accessor: 'first_name',
            Cell: (props : any) => <span className="popup-toggle" onClick={() => setActivePopup(props.original)}>{props.value}</span>
        },
        {
            Header: 'Bedrijf',
            accessor: 'company',
        },
        {
            Header: 'Telefoonnummer',
            accessor: 'phonenumber',
        },
        {
            Header: 'Huisartsenlijn',
            accessor: 'doctor_phone',
        },
        {
            Header: 'Fax',
            accessor: 'fax',
        },
        {
            Header: '',
            maxWidth: 40,
            accessor: 'id',
            Cell: (props : any) => <div className="table-actions-column"><NavLink to={'/contact/update/' + props.value}><FontAwesomeIcon icon={faPencil} /></NavLink></div>
        },
    ];

    const _filterData = (contacts : Contact[] ) => {
        let returnContacts : Contact[] = [];

        if ( !searchValue ) {
            return contacts;
        }
        contacts.forEach((item : Contact) => {
            if ( JSON.stringify(item).toLowerCase().includes(searchValue.toLowerCase())) {
                returnContacts.push(item);
            }
        })

        return returnContacts;
    }

    return (
        <div className="list-overview">
                { activePopup ? <Popup contact={activePopup} dismiss={() => setActivePopup(undefined)} /> : null }
                <div className="list-overview__header">
                    <NavLink to="/contact/create" className="button button--black">Toevoegen</NavLink>
                    <input type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Zoeken"/>
                </div>
                <div className="list-overview__table">
                    <ReactTable
                        defaultSorted={[{
                            id: 'last_name',
                            desc: false
                        }]}
                        data={_filterData(props.Contacts)}
                        defaultPageSize={100}
                        columns={columns}
                        previousText="Vorige"
                        nextText="Volgende"
                        loadingText="Laden..."
                        noDataText="Geen data beschikbaar"
                        pageText="Pagina"
                        ofText="van"
                        rowsText="contacten"
                        />
                </div>

        </div>


    )
}


const mapStateToProps = ( state : RootState ) => {

    return {
        Contacts : state.Contact.contacts
    }
}

export default connect( mapStateToProps )( Router );
