import {
    SET_CONTACT_CRUD_PROCESSING,
    SET_FETCH_CONTACTS_PROCESSING,
    SET_USER_CRUD_PROCESSING,
    SET_FETCH_USERS_PROCESSING,
} from "./actionTypes";


import {
    SetContactCrudProcessing,
    SetContactFetchProcessing,
    SetUserCrudProcessing,
    SetUserFetchProcessing,
} from "./types";


export const setContactCrudProcessing = ( payload : boolean ): SetContactCrudProcessing => ({
    type: SET_CONTACT_CRUD_PROCESSING,
    payload : payload
});

export const setContactFetchProcessing = ( payload : boolean ): SetContactFetchProcessing => ({
    type: SET_FETCH_CONTACTS_PROCESSING,
    payload : payload
});

export const setUserCrudProcessing = ( payload : boolean ): SetUserCrudProcessing => ({
    type: SET_USER_CRUD_PROCESSING,
    payload : payload
});

export const setUserFetchProcessing = ( payload : boolean ): SetUserFetchProcessing => ({
    type: SET_FETCH_USERS_PROCESSING,
    payload : payload
});
