import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/user/actions';
import { RootState, AppDispatch } from '../../../redux/config/store';


const Login = ( props : any) => {

    useEffect(() => {
        props.actions.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


    return (
        <React.Fragment>

        </React.Fragment>
    )
}


const mapStateToProps = ( state : RootState ) => {
    return {

    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...actions }, dispatch) })

export default connect( mapStateToProps, mapDispatchToProps )( Login );
