import { useEffect } from 'react';
import { FontAwesomeIcon}  from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface PopupProps {
    contact : Contact
    dismiss : any
}

const Popup = (props : PopupProps) => {

    useEffect(() => {
        const handleEsc = (event : any) => {
        if (event.keyCode === 27) {
            props.dismiss();
        }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
    return (
        <div className="popup">

            <div className="popup__window">
                <div className="popup__window__header">
                    <div className="popup__window__header__title">
                        <h2>{props.contact.first_name} {props.contact.last_name} </h2>
                    </div>
                    <div className="popup__window__header__dismiss">
                        <div className="popup__window__header__dismiss__icon" onClick={props.dismiss}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                </div>
                <div className="popup__window__content">
                    <table className="popup__window__content__table">
                        <tr>
                            <td>Naam</td><td>{ props.contact.first_name } { props.contact.last_name } </td>
                        </tr>
                        <tr>
                            <td>Telefoon</td><td>{ props.contact.phonenumber } </td>
                        </tr>
                        <tr>
                            <td>Bedrijf</td><td>{ props.contact.company } </td>
                        </tr>
                        <tr>
                            <td>Mobiel</td><td>{ props.contact.mobile } </td>
                        </tr>
                        <tr>
                            <td>Fax</td><td>{ props.contact.fax } </td>
                        </tr>
                        <tr>
                            <td>E-mail</td><td>{ props.contact.email } </td>
                        </tr>
                        <tr>
                            <td>Straat en huisnummer</td><td>{ props.contact.address_number } </td>
                        </tr>
                        <tr>
                            <td>Postcode</td><td>{ props.contact.zipcode } </td>
                        </tr>
                        <tr>
                            <td>Plaats</td><td>{ props.contact.city } </td>
                        </tr>
                        <tr>
                            <td>Extra informatie</td><td dangerouslySetInnerHTML={{__html : props.contact.extra ? props.contact.extra : '' }}></td>
                        </tr>
                    </table>
                </div>
                <div className="popup__window__footer">
                    <div className="button button--black" onClick={props.dismiss}>Sluiten</div>
                </div>
            </div>

        </div>


    )
}
export default Popup
