import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setContacts, getAllContactsRequest } from "./actions";
import { push } from 'react-router-redux';
import {
    CREATE_CONTACT_REQUEST,
    UPDATE_CONTACT_REQUEST,
    DELETE_CONTACT_REQUEST,
    GET_ALL_CONTACTS_REQUEST
} from "./actionTypes";
import {
    CreateContactRequest,
    UpdateContactRequest,
    DeleteContactRequest,
    GetAllContactsRequest
} from './types';
import { setMessage } from '../message/actions';
import { setContactCrudProcessing, setContactFetchProcessing } from '../processing/actions';
const createContactApiRequest = ( contact : Contact ) => axios.post<any>(window.apiUrl + '/contact/create', contact).then(response => { return response.data?.contact})
const updateContactApiRequest = (  contact : Contact ) => axios.post<any>(window.apiUrl + '/contact/update', contact).then(response => { return response.data?.contact})
const deleteContactApiRequest = ( contact : Contact ) => axios.post<any>(window.apiUrl + '/contact/delete', contact).then(response => { return response.data })
const getAllContactsApiRequest = () => axios.get<any>(window.apiUrl + '/contact/list').then(response => { return response.data })

function* deleteContact( action : DeleteContactRequest) {
    try {
        yield put(setContactCrudProcessing(true));
        yield call(deleteContactApiRequest, action.payload );
        yield put(getAllContactsRequest(false));
        yield put(setContactCrudProcessing(false));
        yield put(push('/'));
        yield put(
            setMessage({
                notification : {
                    message : 'Contact verwijderd',
                    type : 'success'
                }
            })
        );

      }catch(e : any) {
          yield put(setContactCrudProcessing(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Kon contact niet verwijderen: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* createContact( action : CreateContactRequest) {
    try {
        yield put(setContactCrudProcessing(true));
        yield call(createContactApiRequest, action.payload);
        yield put(getAllContactsRequest(false));
        yield put(setContactCrudProcessing(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Contact aangemaakt',
                    type : 'success'
                }
            })
        );

      }catch(e : any) {
          yield put(setContactCrudProcessing(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Kon contact niet aanmaken: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* updateContact( action : UpdateContactRequest) {
    try {
        yield put(setContactCrudProcessing(true));
        yield call(updateContactApiRequest, action.payload);
        yield put(getAllContactsRequest());
        yield put(setContactCrudProcessing(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Contact geupdatet',
                    type : 'success'
                }
            })
        );

      }catch(e : any) {
          yield put(setContactCrudProcessing(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Kon contact niet updaten: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}


function* getAllContacts( action : GetAllContactsRequest) {
    try {

        if ( action.payload ) {
            yield put(setContactFetchProcessing(true));
        }
        const contacts : Contact[] = yield call(getAllContactsApiRequest);
        if ( contacts ) {
            yield put(setContacts(contacts));
        }
        yield put(setContactFetchProcessing(false));
      }catch(e : any) {
          yield put(setContactFetchProcessing(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Contacten ophalen mislukt: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}


function* contactSaga() {
  yield all([
      takeLatest(CREATE_CONTACT_REQUEST, createContact),
      takeLatest(UPDATE_CONTACT_REQUEST, updateContact),
      takeLatest(DELETE_CONTACT_REQUEST, deleteContact),
      takeLatest(GET_ALL_CONTACTS_REQUEST, getAllContacts),
  ]);
}

export default contactSaga;
