import React, { useState, useEffect }  from 'react';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/config/store';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import * as userActions from '../../redux/user/actions';
import { bindActionCreators } from 'redux';
import ConfirmPopup from '../confirmPopup';

/**
*   @component
*	@Description     Router containing all routes and redirects
*/
const Form = (props : any) => {
        let { id } : { id? : string | undefined} = useParams();
        const [ confirmDeletePopupOpen, setConfirmDeletePopupOpen ] = useState<boolean>(false);
        const { register, handleSubmit, reset, formState: { errors }, control } = useForm<User>();
        useEffect(() => {
            if ( id ) {
                if ( props.Users.length ) {

                    const user: User = props.Users.find((item: User) => item.id?.toString() === id);

                    // -- Customer found in props, reset form values.
                    if ( user ) {
                        reset(user);
                    }
                }
            }
        }, [props.Users, id, reset]);


        const _onSubmit = handleSubmit((data : Contact) : void => {
            if ( data.id ) {
                props.actions.updateUserRequest(data);
            } else {
               props.actions.createUserRequest(data);
            }
        });

        const _deleteContact = () => {

            if ( id ) {
                if ( props.Users.length ) {
                    const user: User = props.Users.find((item: User) => item.id?.toString() === id);
                    props.actions.deleteUserRequest(user);
                    setConfirmDeletePopupOpen(false);
                }
            }

        }

    return (
        <div className="form-page">
            { confirmDeletePopupOpen ? <ConfirmPopup dismiss={() => setConfirmDeletePopupOpen(false)} confirm={() => _deleteContact()}/> : null }
            <form className="form" onSubmit={_onSubmit}>
                <div className="form__row">
                    <label>Naam</label>
                    <input {...register("name", { required: true})} placeholder="Naam" />
                    {errors.name && <span>Dit veld is verplicht</span>}
                </div>
                <div className="form__row">
                    <label>E-mailadres</label>
                    <input {...register("email", { required: true})} placeholder="E-mailadres" />
                    {errors.email && <span>Dit veld is verplicht</span>}
                </div>
                <div className="form__row">
                    <label>Wachtwoord</label>
                    <input {...register("password")}  placeholder="Wachtwoord" />

                </div>

                <div className="form__row form__row--submit">
                    <button className="button button--black" type="submit">Opslaan</button>

                    { id ? <div className="button button--red" onClick={() => setConfirmDeletePopupOpen(true)}>Verwijderen</div> : null}
                </div>
            </form>
        </div>

    )
}


const mapStateToProps = ( state : RootState ) => {

    return {
        Users : state.User.users
    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...userActions }, dispatch) })

export default connect( mapStateToProps, mapDispatchToProps )( Form );
