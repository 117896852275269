import { combineReducers } from "redux";
import UserReducer from '../user/reducer';
import ProcessingReducer from '../processing/reducer';
import ContactReducer from '../contact/reducer';

const rootReducer = (routerReducer : any) => combineReducers({
    router: routerReducer,
    User : UserReducer,
    Contact : ContactReducer,
    Processing : ProcessingReducer

});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
