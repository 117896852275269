import React, { useState, useEffect }  from 'react';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/config/store';
import { Controller, useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import WYSIWYGEditor from "../WYSIWYG";
import { stripHtml } from "string-strip-html";
import * as contactActions from '../../redux/contact/actions';
import { bindActionCreators } from 'redux';
import ConfirmPopup from '../confirmPopup';

/**
*   @component
*	@Description     Router containing all routes and redirects
*/
const Form = (props : any) => {
        let { id } : { id? : string | undefined} = useParams();
        const [ confirmDeletePopupOpen, setConfirmDeletePopupOpen ] = useState<boolean>(false);
        const { register, handleSubmit, reset, formState: { errors }, control } = useForm<Contact>();
        useEffect(() => {
            if ( id ) {
                if ( props.Contacts.length ) {

                    const contact: Contact = props.Contacts.find((item: Contact) => item.id?.toString() === id);

                    // -- Customer found in props, reset form values.
                    if ( contact ) {
                        reset(contact);
                    }
                }
            }
        }, [props.Contacts, id, reset]);


        const _onSubmit = handleSubmit((data : Contact) : void => {
            if ( data.id ) {
                props.actions.updateContactRequest(data);
            } else {
                props.actions.createContactRequest(data);
            }
        });

        const _deleteContact = () => {

            if ( id ) {
                if ( props.Contacts.length ) {
                    const contact: Contact = props.Contacts.find((item: Contact) => item.id?.toString() === id);
                    props.actions.deleteContactRequest(contact);
                    setConfirmDeletePopupOpen(false);
                }
            }

        }

    return (
        <div className="form-page">
            { confirmDeletePopupOpen ? <ConfirmPopup dismiss={() => setConfirmDeletePopupOpen(false)} confirm={() => _deleteContact()}/> : null }
            <form className="form" onSubmit={_onSubmit}>
                <div className="form__row">
                    <label>Voornaam</label>
                    <input {...register("first_name")} placeholder="Voornaam" />
                </div>
                <div className="form__row">
                    <label>Achternaam</label>
                    <input {...register("last_name")} placeholder="Achternaam" />
                </div>
                <div className="form__row">
                    <label>Bedrijf</label>
                    <input {...register("company")} placeholder="Bedrijf" />
                </div>
                <div className="form__row">
                    <label>Telefoonnummer</label>
                    <input {...register("phonenumber")} placeholder="Telefoon" />
                </div>
                <div className="form__row">
                    <label>Huisartsenlijn</label>
                    <input {...register("doctor_phone")} placeholder="Huisartsenlijn" />
                </div>
                <div className="form__row">
                    <label>Mobiel</label>
                    <input {...register("mobile")} placeholder="Mobiel" />
                </div>
                <div className="form__row">
                    <label>Fax</label>
                    <input {...register("fax")} placeholder="Fax" />
                </div>
                <div className="form__row">
                    <label>E-mail</label>
                    <input {...register("email")} placeholder="E-mailadres" />
                </div>
                <div className="form__row">
                    <label>Adres</label>
                    <input {...register("address_number")} placeholder="Straat en huisnummer" />
                </div>
                <div className="form__row">
                    <label>Postcode</label>
                    <input {...register("zipcode")} placeholder="Postcode" />
                </div>
                <div className="form__row">
                    <label>Plaats</label>
                    <input {...register("city")} placeholder="Plaats" />
                </div>
                <div className="form__row">
                    <Controller
                        render={({ field }) => <WYSIWYGEditor {...field} />}
                        name="extra"
                        control={control}
                        defaultValue=""

                    />
                </div>
                <div className="form__row form__row--submit">
                    <button className="button button--black" type="submit">Opslaan</button>

                    { id ? <div className="button button--red" onClick={() => setConfirmDeletePopupOpen(true)}>Verwijderen</div> : null}
                </div>
            </form>
        </div>

    )
}


const mapStateToProps = ( state : RootState ) => {

    return {
        Contacts : state.Contact.contacts
    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...contactActions }, dispatch) })

export default connect( mapStateToProps, mapDispatchToProps )( Form );
