import React, { useEffect, useState } from 'react';
//import { NavLink } from 'react-router-dom';

import Router from '../router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReduxConfig  from "../../redux/config/store";
import Boot from '../../redux/config/boot';
import { connect } from 'react-redux';

import { RootState } from '../../redux/config/store';

/**
*   Main app component. Contains navigation and router. Runs the Redux boot function once.
*   @component
*/
const App: React.FC = (props : any) => {
    const [ bootRan, setBootRan ] = useState<boolean>(false);

    useEffect(() =>  {
        if ( bootRan === false) {
            Boot(ReduxConfig.store.dispatch);
            setBootRan(true);
        }

        return () => {
            //props.actions.setSearchTerm(undefined);
        }
    }, [ bootRan ]);

    const _isWorking = () => {
        if ( props.ContactFetchProcessing || props.ContactCrudProcessing || props.UserFetchProcessing || props.UserCrudProcessing ) {
            return <div  className="loader"/>
        }

    }
    return (
        <React.Fragment>
            { _isWorking() }
            <Router />
        </React.Fragment>
    )
}

const mapStateToProps = ( state : RootState ) => {
    console.log(state);
    return {
        ContactFetchProcessing : state.Processing.contact_fetch,
        ContactCrudProcessing : state.Processing.contact_crud,
        UserFetchProcessing : state.Processing.user_fetch,
        UserCrudProcessing : state.Processing.user_fetch
    }
}

export default connect( mapStateToProps )( App );
