import {
    CHECK_VALID_TOKEN,
    SET_TOKEN,
    GET_TOKEN_FROM_LOCALSTORAGE,
    SET_USER,
    AUTHENTICATE,
    SET_USERS,
    GET_USERS,
    LOGOUT,
    UPDATE_USER,
    CREATE_USER,
    DELETE_USER
} from "./actionTypes";


import {

    CheckValidToken,
    Authenticate,
    SetToken,
    AuthenticationPayload,
    GetTokenFromLocalStorage,
    SetUser,
    SetUsers,
    GetUsers,
    Logout,
    UpdateUser,
    CreateUser,
    DeleteUser
} from "./types";

export const logout = (): Logout => ({
    type : LOGOUT
})

export const updateUserRequest = ( payload : User ): UpdateUser => ({
    type : UPDATE_USER,
    payload : payload
})

export const createUserRequest = ( payload : User ): CreateUser => ({
    type : CREATE_USER,
    payload : payload
})

export const deleteUserRequest = ( payload : User ): DeleteUser => ({
    type : DELETE_USER,
    payload : payload
})

export const setUser = ( payload : User): SetUser => ({
    type: SET_USER,
    payload : payload
});

export const getUsers = ( payload : boolean = true): GetUsers => ({
    type: GET_USERS,
    payload : payload
});

export const setUsers = ( payload : User[]): SetUsers => ({
    type: SET_USERS,
    payload : payload
});

export const checkValidToken = () : CheckValidToken => ({
    type : CHECK_VALID_TOKEN
})

export const authenticate = ( payload : AuthenticationPayload ) : Authenticate => ({
    type : AUTHENTICATE,
    payload : payload
})

export const setToken = ( payload : string | null) : SetToken => ({
    type : SET_TOKEN,
    payload : payload
})

export const getTokenFromLocalStorage = () : GetTokenFromLocalStorage => ({
    type : GET_TOKEN_FROM_LOCALSTORAGE
})
