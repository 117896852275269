import { useState }  from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux/config/store';
import ReactTable from 'react-table'
import { FontAwesomeIcon}  from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';

/**
*   @component
*	@Description     Router containing all routes and redirects
*/
const Router = (props : any) => {

    const [ searchValue, setSearchValue ] = useState<string>('');


    const columns = [
        {
            Header: 'Naam',
            accessor: 'name'
        },
        {
            Header: 'E-mail',
            accessor: 'email',
            Cell: (props : any) => <span className='number'>{props.value}</span> // Custom cell components!
        },
        {
            Header: '',
            accessor: 'id',
            maxWidth: 40,
            Cell: (props : any) => <div className="table-actions-column"><NavLink to={'/user/update/' + props.value}><FontAwesomeIcon icon={faPencil} /></NavLink></div>
        },
    ];

    const _filterData = (users : User[] ) => {
        let returnUsers : User[] = [];

        if ( !searchValue ) {
            return users;
        }
        users.forEach((item : User) => {
            if ( JSON.stringify(item).toLowerCase().includes(searchValue.toLowerCase())) {
                returnUsers.push(item);
            }
        })

        return returnUsers;
    }

    return (
        <div className="list-overview">
            <div className="list-overview__header">
                <NavLink to="/user/create" className="button button--black">Toevoegen</NavLink>
                <input type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Zoeken"/>
            </div>
            <div className="list-overview__table">
                <ReactTable data={_filterData(props.Users)} defaultPageSize={100} columns={columns} previousText="Vorige" nextText="Volgende" loadingText="Laden..." noDataText="Geen data beschikbaar" pageText="Pagina" ofText="van" rowsText="contacten"/>
            </div>
        </div>


    )
}


const mapStateToProps = ( state : RootState ) => {

    return {
        Users : state.User.users
    }
}

export default connect( mapStateToProps )( Router );
