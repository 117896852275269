import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../redux/user/actions';
import { RootState, AppDispatch } from '../../redux/config/store';
const Login = ( props : any) => {
    const [ password, setPassword ] = useState<string>('');
    const [ email, setEmail ] = useState<string>('');
    const [ tokenFromLocalStorageChecked, setTokenFromLocalStorageChecked ] = useState<boolean>(false);

    useEffect(() => {
        // -- Check authorization
        if ( !tokenFromLocalStorageChecked ) {
            setTokenFromLocalStorageChecked(true);
            props.actions.getTokenFromLocalStorage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.location]);

   const _formSubmit = (e : React.SyntheticEvent) : void => {
       e.preventDefault();

       props.actions.authenticate({
           email : email,
           password : password
       })
   }

    return (
        <React.Fragment>
            <div className="auth">
                <div className="auth__title">
                    Inloggen
                </div>
                <div className="auth__text">
                    Login op je bestaande account
                </div>

                <div className="auth__login-form__wrapper">
                    <form onSubmit={_formSubmit} className="auth__login-form">
                        <input type="email" placeholder="E-mailadres" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <input type="password" placeholder="Wachtwoord" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <button className="button button--submit">Inloggen</button>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = ( state : RootState ) => {
    return {

    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...actions }, dispatch) })

export default connect( mapStateToProps, mapDispatchToProps )( Login );
