export const CHECK_VALID_TOKEN = "CHECK_VALID_TOKEN";
export const AUTHENTICATE = "AUTHENTICATE";
export const SET_TOKEN = "SET_TOKEN";
export const GET_TOKEN_FROM_LOCALSTORAGE = "GET_TOKEN_FROM_LOCALSTORAGE";
export const SET_USER = "SET_USER";
export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";
export const DELETE_USER = "DELETE_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const LOGOUT = "LOGOUT";
