import {
    CREATE_CONTACT_REQUEST,
    UPDATE_CONTACT_REQUEST,
    DELETE_CONTACT_REQUEST,
    SET_CONTACTS,
    GET_ALL_CONTACTS_REQUEST
} from "./actionTypes";


import {
    SetContacts,
    CreateContactRequest,
    UpdateContactRequest,
    DeleteContactRequest,
    GetAllContactsRequest
} from "./types";


export const createContactRequest = ( payload : Contact): CreateContactRequest => ({
    type: CREATE_CONTACT_REQUEST,
    payload : payload
});

export const getAllContactsRequest = ( payload : boolean = true ): GetAllContactsRequest => ({
    type: GET_ALL_CONTACTS_REQUEST,
    payload : payload // -- Processing
});

export const setContacts = (payload : Contact[]): SetContacts => ({
    type: SET_CONTACTS,
    payload : payload
});

export const updateContactRequest = ( payload : Contact): UpdateContactRequest => ({
    type: UPDATE_CONTACT_REQUEST,
    payload : payload
});

export const deleteContactRequest = ( payload : Contact ): DeleteContactRequest => ({
    type: DELETE_CONTACT_REQUEST,
    payload : payload
});
